import { useState, useEffect, useCallback } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useTickets = ({
  searchParam,
  tags,
  users,
  pageNumber,
  status,
  date,
  updatedAt,
  showAll,
  queueIds,
  withUnreadMessages,
}) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [tickets, setTickets] = useState([]);

  // Função de busca
  const fetchTickets = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/tickets", {
        params: {
          searchParam,
          pageNumber,
          tags,
          users,
          status,
          date,
          updatedAt,
          showAll,
          queueIds,
          withUnreadMessages,
        },
      });
      setTickets(data.tickets);
      setHasMore(data.hasMore);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  }, [
    searchParam,
    tags,
    users,
    pageNumber,
    status,
    date,
    updatedAt,
    showAll,
    queueIds,
    withUnreadMessages,
  ]);

  // Debounce para controlar o tempo de espera
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchTickets();
    }, 50);

    return () => clearTimeout(delayDebounceFn);
  }, [fetchTickets]); // fetchTickets já é memorizado

  return { tickets, loading, hasMore };
};

export default useTickets;