import React, { useState, useEffect, useContext, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import AttachmentIcon from '@mui/icons-material/Attachment';
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl, Grid, IconButton } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize, head } from "lodash";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import AttachFile from "@material-ui/icons/AttachFile";
import ConfirmationModal from "../ConfirmationModal";
import MessageVariablesPicker from "../MessageVariablesPicker";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  titleText: {
    fontWeight: 600,
    fontSize: "20px",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  textField: {
    backgroundColor: theme.palette.inputBackgroundColor,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "16px",
    '& .MuiOutlinedInput-root': {
      padding: "6px",
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input': {
        backgroundColor: theme.palette.inputBackgroundColor,
        border: 'none !important',
      },
      "& .MuiAutocomplete-clearIndicator": {
        display: "none",
      },
    },
    '& .MuiInputBase-input': {
      color: "#7d7d7d",
      fontSize: "16px",
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string()
    .min(5, "Mensagem muito curta")
    .required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAtDate: Yup.date().required("Data é obrigatória"),
  sendAtTime: Yup.string().required("Hora é obrigatória"),
});

const ScheduleModal = ({ open, onClose, scheduleId, contactId, cleanContact, reload }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    body: "",
    contactId: "",
    sendAtDate: moment().format('YYYY-MM-DD'),
    sendAtTime: moment().format('HH:mm'),
  };

  const initialContact = {
    id: "",
    name: "",
  };

  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const messageInputRef = useRef();

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find(c => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      (async () => {
        try {
          const { data: contactList } = await api.get('/contacts/list', { params: { companyId } });
          let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList]);
          }
          if (contactId) {
            setSchedule(prevState => ({ ...prevState, contactId }));
          }
          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);
          setSchedule(prevState => ({ ...prevState, ...data, sendAtDate: moment(data.sendAt).format('YYYY-MM-DD'), sendAtTime: moment(data.sendAt).format('HH:mm') }));
          setCurrentContact(data.contact);
        } catch (err) {
          toastError(err);
        }
      })();
    }
  }, [scheduleId, contactId, open, user]);

  const handleClose = () => {
    onClose();
    setAttachment(null);
    setSchedule(initialState);
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveSchedule = async values => {
    const { sendAtDate, sendAtTime, ...rest } = values;
    const sendAt = `${sendAtDate}T${sendAtTime}`;
    const scheduleData = { ...rest, sendAt, userId: user.id };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${scheduleId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload === 'function') reload();
      if (contactId && typeof cleanContact === 'function') {
        cleanContact();
        history.push('/schedules');
      }
    } catch (err) {
      toastError(err);
    }
    setCurrentContact(initialContact);
    setSchedule(initialState);
    handleClose();
  };

  const handleClickMsgVar = async (msgVar, setValueFunc) => {
    const el = messageInputRef.current;
    const firstHalfText = el.value.substring(0, el.selectionStart);
    const secondHalfText = el.value.substring(el.selectionEnd);
    const newCursorPos = el.selectionStart + msgVar.length;

    setValueFunc("body", `${firstHalfText}${msgVar}${secondHalfText}`);

    await new Promise(r => setTimeout(r, 100));
    messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`);
      setSchedule(prev => ({
        ...prev,
        mediaPath: null,
      }));
      toast.success(i18n.t("scheduleModal.toasts.deleted"));
      if (typeof reload === "function") reload();
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle disableTypography className={classes.titleContainer}>
          <div>
            <Typography className={classes.titleText}>
              {schedule.status === 'ERRO' ? 'Erro de Envio' : `Mensagem ${capitalize(schedule.status)}`}
            </Typography>
            <Typography style={{ fontSize: 14, color: "grey" }}>
              {i18n.t("scheduleModal.subtitle")}
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div style={{ display: "none" }}>
          <input
            type="file"
            accept=".png,.jpg,.jpeg"
            ref={attachmentFile}
            onChange={handleAttachmentFile}
          />
        </div>
        <Formik
          initialValues={schedule}
          enableReinitialize={true}
          validationSchema={ScheduleSchema}
          onSubmit={(values, actions) => {
            setTimeout(() => {
              handleSaveSchedule(values);
              actions.setSubmitting(false);
            }, 50);
          }}
        >
          {({ touched, errors, isSubmitting, values, setFieldValue }) => (
            <Form>
              <DialogContent dividers>
                <div>
                  <label htmlFor="contact-select" style={{ fontWeight: 700, fontSize: 15, padding: 0, marginBottom: "-2px" }}>
                    {i18n.t("scheduleModal.form.contact")}
                  </label>
                  <div className={classes.multFieldLine}>
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        fullWidth
                        value={currentContact}
                        options={contacts}
                        onChange={(e, contact) => {
                          const contactId = contact ? contact.id : '';
                          setSchedule({ ...schedule, contactId });
                          setCurrentContact(contact || initialContact);
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => value.id === option.id}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Selecione o contato" className={classes.textField} />}
                      />
                    </FormControl>
                  </div>
                </div>
                <div style={{ marginTop: "16px" }} className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    rows={9}
                    multiline
                    label={i18n.t("scheduleModal.form.body")}
                    name="body"
                    inputRef={messageInputRef}
                    error={touched.body && Boolean(errors.body)}
                    helperText={touched.body && errors.body}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <Grid style={{ marginTop: "16px" }} item>
                  <MessageVariablesPicker
                    disabled={isSubmitting}
                    onClick={value => handleClickMsgVar(value, setFieldValue)}
                  />
                </Grid>
                <div style={{ marginTop: "32px" }} className={classes.multFieldLine}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    label={i18n.t("scheduleModal.form.sendAtDate")}
                    type="date"
                    name="sendAtDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAtDate && Boolean(errors.sendAtDate)}
                    helperText={touched.sendAtDate && errors.sendAtDate}
                    className={classes.textField}
                    fullWidth
                  />
                  <Field
                    as={TextField}
                    variant="outlined"
                    label={i18n.t("scheduleModal.form.sendAtTime")}
                    type="time"
                    name="sendAtTime"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sendAtTime && Boolean(errors.sendAtTime)}
                    helperText={touched.sendAtTime && errors.sendAtTime}
                    className={classes.textField}
                    fullWidth
                  />
                </div>
                {(schedule.mediaPath || attachment) && (
                  <Grid xs={12} item>
                    <Button startIcon={<AttachFile />}>
                      {attachment ? attachment.name : schedule.mediaName}
                    </Button>
                    <IconButton
                      onClick={() => setConfirmationOpen(true)}
                      color="secondary"
                    >
                      <DeleteOutline color="secondary" />
                    </IconButton>
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                {!attachment && !schedule.mediaPath && (
                  <Button
                    onClick={() => attachmentFile.current.click()}
                    disabled={isSubmitting}
                    variant="outlined"
                    style={{ border: "none", marginRight: "auto", color: "grey" }}
                    startIcon={<AttachmentIcon />}
                  >
                    {i18n.t("quickMessages.buttons.attach")}
                  </Button>
                )}
                <Button
                  onClick={handleClose}
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  {i18n.t("scheduleModal.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                  className={classes.btnWrapper}
                >
                  {scheduleId
                    ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                    : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
