import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { AuthContext } from "../../context/Auth/AuthContext";
import LogoSVG from "./chatsaclogonovo.svg"; // Importa o SVG da logo
import ObjectsSVG from "./icones.svg"; // Importa o SVG dos objetos
import { MdEmail } from "react-icons/md";
import './style.css'


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh", // Garante que ocupe 100% da altura da viewport
    overflow: "hidden", // Remove qualquer possibilidade de scroll
    justifyContent: "center", // Centraliza o conteúdo horizontalmente
    backgroundColor: "#111433", // Fundo preto para as áreas excedentes
    zIndex: 4,
  },
  container: {
    display: "flex",
    width: "100%",
    maxWidth: "1920px",
    padding: 0,
    backgroundColor: "#111433",
    borderRadius: "24px", // Aplique o borderRadius no container
    overflow: "hidden", // Garante que o conteúdo não vaze do container
  },
  leftSection: {
    flex: 1,
    zIndex: 1,
    backgroundColor: "#87C945",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    padding: "2rem",
    borderRadius: '24px',
    maxWidth: '861px',
    '@media (max-width: 1366px)': { // Ajuste o valor conforme necessário para definir o que é considerado "tela pequena"
      maxWidth: '50%',
    },
    [theme.breakpoints.down("sm")]: {
      display: "none", // Oculta a seção esquerda em dispositivos móveis
    },
  },
  logo: {
    alignSelf: "flex-start", // Alinha a logo no topo
    marginBottom: "2rem",
  },
  textContent: {
    color: "#000",
    marginTop: "20px",
    textAlign: "left",
    fontSize: "56px", // Tamanho do texto H1
    fontWeight: "600",
    maxWidth: "620px",
    lineHeight: "120%",
    '@media (max-width: 1366px)': { // Ajuste o valor conforme necessário para definir o que é considerado "tela pequena"
      fontSize: "46px",
      maxWidth: "420px",
    },
  },
  objectsContainer: {
    zIndex: 2,
    justifyContent: 'end',
    position: "relative",
    width: '1024px',
    height: "500px",
    left: '-50px',
    transform: 'scale(1.4)',
    bottom: '-130px',
    // marginTop: "230px", // Adiciona margem superior para descer o container

    '@media (max-width: 1366px)': { // Ajuste o valor conforme necessário para definir o que é considerado "tela pequena"
      transform: 'scale(1.2)',
      bottom: '-20px',
      left: '-140px',
    },
  },

  rightSection: {
    flex: 1,
    backgroundColor: "#111433",
    display: "flex",
    zIndex: 2,
    alignItems: "center",
    justifyContent: "center",
    overflowY: "auto", // Permite scroll apenas se necessário
  },
  loginContainer: {
    width: "100%",
    maxWidth: "400px",
    padding: "2rem",
    borderRadius: "8px",
    justifyContent: 'center',
    textAlign: 'center'
  },
  form: {
    width: "100%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#106de0",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0d5bb8",
    },
  },
  link: {
    color: "#7ED957",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ email: "", password: "" });
  const { handleLogin } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const isFormValid = user.email && user.password;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid) {
      handleLogin(user);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.leftSection}>
          <img src={LogoSVG} alt="Logo" className={classes.logo} />
          <Typography component="h1" className={classes.textContent}>
            Encontre seus clientes de forma rápida.
          </Typography>
          <img src={ObjectsSVG} alt="Objects" className={classes.objectsContainer} />
        </div>
        <div className={classes.rightSection}>
          <Container component="main" maxWidth="xs" className={classes.loginContainer}>
            <Typography component="h1" variant="h5" style={{ color: "#fff", fontSize: '28px', justifySelf: 'center', fontWeight: '600', width: '100%' }}>
              Login
            </Typography>
            <Typography component="h1" variant="h5" style={{ color: "#94949A", fontSize: '16px', justifySelf: 'center', fontWeight: '400', width: '100%', marginTop: '5px' }}>
              Acesse sua conta ChatSac
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleSubmit} style={{ width: '316px' }}>
              <Typography component="h1" variant="h5" style={{ color: "#fff", fontSize: '14px', justifySelf: 'center', fontWeight: '400', width: '100%', marginTop: '25px', textAlign: 'start', marginBottom: '5px' }}>
                Email
              </Typography>
              <div className="form" style={{ width: '316px' }}>

                <label htmlFor="email" style={{ width: '316px' }} >
                  <input
                    className="input"
                    type="text"
                    required
                    placeholder="Digite seu email"
                    id="email"
                    name="email"
                    style={{ width: '316px' }}
                    value={user.email}
                    onChange={handleChangeInput}
                  />
                  <div className="fancy-bg"></div>
                  <div className="search">
                    <MdEmail color="white" size={30} />
                  </div>
                </label>
              </div>
              <Typography component="h1" variant="h5" style={{ color: "#fff", fontSize: '14px', justifySelf: 'center', fontWeight: '400', width: '100%', textAlign: 'start', marginBottom: '5px', marginTop: '10px' }}>
                Senha
              </Typography>
              <div className="form" style={{ width: '316px' }}>
                <label htmlFor="email" style={{ width: '316px' }} >
                  <input
                    className="input"
                    type="password"
                    required
                    placeholder="Digite sua senha"
                    id="password"
                    name="password"
                    style={{ width: '316px' }}
                    value={user.password}
                    onChange={handleChangeInput}
                    autoComplete="current-password"
                  />
                  <div className="fancy-bg"></div>
                  <div className="search">
                    <MdEmail color="white" size={30} />
                  </div>
                </label>
              </div>

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isFormValid}
                style={{
                  backgroundColor: isFormValid ? '#87C945' : '#262945',
                  color: isFormValid ? 'black' : '#94949A',
                  borderRadius: '16px',
                  cursor: isFormValid ? 'pointer' : 'not-allowed',
                }}
                className={classes.submit}
              >
                Entrar
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <RouterLink to="/forgot-password" className={classes.link}>
                    Esqueceu sua senha?
                  </RouterLink> */}
                </Grid>
                <Grid item>
                  {/* <RouterLink to="/signup" className={classes.link}>
                    Não possui uma conta? Crie aqui
                  </RouterLink> */}
                </Grid>
              </Grid>
            </form>

          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;
