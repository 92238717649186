import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green, grey, red, blue } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";

import api from "../../services/api";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import { TicketsContext } from "../../context/Tickets/TicketsContext";
import toastError from "../../errors/toastError";
import { v4 as uuidv4 } from "uuid";

import AndroidIcon from "@material-ui/icons/Android";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TicketMessagesDialog from "../TicketMessagesDialog";
import ContactTag from "../ContactTag";

const useStyles = makeStyles((theme) => ({
  ticket: {
    position: "relative",
    padding: "12px 0px 12px 6px",
  },

  pendingTicket: {
    cursor: "unset",
  },
  queueTag: {
    background: "#FCFCFC",
    color: theme.palette.text.primary,
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },
  noTicketsDiv: {
    display: "flex",
    height: "100px",
    margin: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  newMessagesCount: {
    position: "absolute",
    alignSelf: "center",
    marginRight: 8,
    marginLeft: "auto",
    top: "50%",
    right: "20px",
    borderRadius: 0,
  },
  noTicketsText: {
    textAlign: "center",
    color: "rgb(104, 121, 146)",
    fontSize: "14px",
    lineHeight: "1.4",
  },
  connectionTag: {
    background: "green",
    color: theme.palette.text.primary,
    marginRight: 1,
    padding: 1,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 3,
    fontSize: "0.8em",
    whiteSpace: "nowrap"
  },
  noTicketsTitle: {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "600",
    margin: "0px",
  },

  contactNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "5px",
  },

  lastMessageTime: {
    justifySelf: "flex-end",
    textAlign: "right",
    position: "relative",
    bottom: -21,
    right: -8,
    fontSize: "10px",
  },

  closedBadge: {
    alignSelf: "center",
    justifySelf: "flex-end",
    marginRight: 32,
    marginLeft: "auto",
  },

  contactLastMessage: {
    // paddingRight: "0%",
    paddingRight: "2px",
    marginLeft: "5px",
  },

  badgeStyle: {
    color: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff",
    backgroundColor: "#6EB131",
  },

  // acceptButton: {
  //   position: "absolute",
  //   right: "108px",
  // },

  acceptButton: {
    position: "absolute",
    left: "50%",
  },

  ticketQueueColor: {
    flex: "none",
    width: "8px",
    height: "100%",
    position: "absolute",
    top: "0%",
    left: "0%",
  },

  ticketInfo: {
    position: "relative",
    top: -13
  },
  secondaryContentSecond: {
    display: 'flex',
    // marginTop: 5,
    //marginLeft: "5px",
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    alignContent: "flex-start",
  },
  ticketInfo1: {
    position: "relative",
    top: 13,
    right: 0
  },
  Radiusdot: {
    "& .MuiBadge-badge": {
      borderRadius: 2,
      position: "inherit",
      height: 16,
      margin: 2,
      padding: 3
    },
    "& .MuiBadge-anchorOriginTopRightRectangle": {
      transform: "scale(1) translate(0%, -40%)",
    },
  },
  SpyOnConversation: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "28px",
    width: "28px",
    borderRadius: "100%",
    bottom: "-2px",
    right: "-2px",
    zIndex: 1,
    cursor: "pointer",
    verticalAlign: "middle",
    backgroundColor: "#6EB131",
    color: theme.palette.type === 'dark' ? theme.palette.cardnormal : "#fff",
  },

}));
{/*PLW DESIGN INSERIDO O dentro do const handleChangeTab*/ }
// O componente principal exibe um item de lista para um ticket específico.
// Ele também lida com ações do usuário, como aceitar, fechar e reabrir o ticket.
const TicketListItemCustom = ({ ticket }) => {
  // Inicializa os estilos usando o hook `makeStyles`
  const classes = useStyles();

  // Hook do React Router para navegação
  const history = useHistory();

  // Estado para controlar o carregamento de ações como aceitar, fechar e reabrir ticket
  const [loading, setLoading] = useState(false);

  // Estados para armazenar informações do ticket
  const [ticketUser, setTicketUser] = useState(null);
  const [ticketQueueName, setTicketQueueName] = useState(null);
  const [ticketQueueColor, setTicketQueueColor] = useState(null);
  const [tag, setTag] = useState([]);
  const [whatsAppName, setWhatsAppName] = useState(null);
  const [setor, setSetor] = useState([]);
  const [contact, setContact] = useState([]);

  // Estado para controlar a exibição do modal de mensagens do ticket
  const [openTicketMessageDialog, setOpenTicketMessageDialog] = useState(false);

  // Obtém o `ticketId` da URL e cria uma referência `isMounted`
  const { ticketId } = useParams();
  const isMounted = useRef(true);

  // Hook do contexto que atualiza o ticket atual selecionado globalmente
  const { setCurrentTicket } = useContext(TicketsContext);

  // Obtém o perfil do usuário logado a partir do contexto de autenticação
  const { user } = useContext(AuthContext);
  const { profile } = user;

  const fetchSetor = async () => {
    if (ticket.user && ticket.user.id) {
      try {
        const { data } = await api.get(`/users/${ticket.user.id}`); // ALTERAÇÃO 2: API para buscar setores
        setSetor(data.queues || []); // Atualiza o estado com os setores
      } catch (error) {
        console.error("Erro ao buscar setor:", error);
        toastError(error); // Exibe erro, se necessário
      }
    }
  };

  // Hook `useEffect` para inicializar os dados do ticket quando o componente é montado
  useEffect(() => {
    fetchSetor();

    // Se o ticket estiver associado a um usuário, salva o nome do usuário
    if (ticket.userId && ticket.user) {
      setTicketUser(ticket.user?.name?.toUpperCase());
    }

    // Salva o nome e a cor da fila associada ao ticket
    setTicketQueueName(ticket.queue?.name?.toUpperCase());
    setTicketQueueColor(ticket.queue?.color);

    // Se houver um WhatsApp associado ao ticket, salva o nome
    if (ticket.whatsappId && ticket.whatsapp) {
      setWhatsAppName(ticket.whatsapp.name?.toUpperCase());
    }

    // Salva as tags associadas ao ticket
    setTag(ticket?.tags);

    // Função de limpeza para desmarcar o componente como montado
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [ticket.user]);

  // Função para fechar o ticket chamando a API correspondente
  const handleCloseTicket = async (id) => {
    setTag(ticket?.tags);
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "closed",
        userId: user?.id,
        queueId: ticket?.queue?.id,
        useIntegration: false,
        promptId: null,
        integrationId: null
      });
    } catch (err) {
      setLoading(false);
      toastError(err); // Exibe um toast em caso de erro
    }
    if (isMounted.current) {
      setLoading(false); // Desativa o carregamento quando a resposta da API é recebida
    }
    history.push(`/tickets/`); // Navega para a lista de tickets
  };

  // Função para reabrir o ticket chamando a API correspondente
  const handleReopenTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
        queueId: ticket?.queue?.id
      });
    } catch (err) {
      setLoading(false);
      toastError(err); // Exibe um toast em caso de erro
    }
    if (isMounted.current) {
      setLoading(false);
    }
    history.push(`/tickets/${ticket.uuid}`); // Navega de volta para o ticket reaberto
  };

  // Função para aceitar o ticket e enviar uma mensagem automática se a configuração permitir
  const handleAcceptTicket = async (id) => {
    setLoading(true);
    try {
      await api.put(`/tickets/${id}`, {
        status: "open",
        userId: user?.id,
      });

      // Checa as configurações da aplicação para verificar se a saudação está ativada
      let settingIndex;

      try {
        const { data } = await api.get("/settings/");
        settingIndex = data.filter((s) => s.key === "sendGreetingAccepted");
      } catch (err) {
        toastError(err); // Exibe um toast em caso de erro
      }

      // Envia uma mensagem de saudação se a configuração estiver habilitada e o ticket não for de um grupo
      if (settingIndex[0].value === "enabled" && !ticket.isGroup) {
        handleSendMessage(ticket.id);
      }

    } catch (err) {
      setLoading(false);
      toastError(err); // Exibe um toast em caso de erro
    }
    if (isMounted.current) {
      setLoading(false);
    }

    history.push(`/tickets/${ticket.uuid}`); // Navega para o ticket aceito
  };

  // Função para enviar uma mensagem automática após aceitar o ticket
  const handleSendMessage = async (id) => {
    const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`;
    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      body: `*Mensagem Automática:*\n${msg.trim()}`,
    };
    try {
      await api.post(`/messages/${id}`, message); // Envia a mensagem para a API
    } catch (err) {
      toastError(err); // Exibe um toast em caso de erro
    }
  };

  // Função para selecionar o ticket e definir como o ticket atual
  const handleSelectTicket = (ticket) => {
    const code = uuidv4(); // Gera um UUID único
    const { id, uuid } = ticket;
    setCurrentTicket({ id, uuid, code }); // Define o ticket no contexto global
  };

  // Função para renderizar informações adicionais do ticket, como se ele é controlado por um chatbot
  const renderTicketInfo = () => {
    if (ticketUser) {
      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
        </>
      );
    } else {
      return (
        <>
          {ticket.chatbot && (
            <Tooltip title="Chatbot">
              <AndroidIcon
                fontSize="small"
                style={{ color: grey[700], marginRight: 5 }}
              />
            </Tooltip>
          )}
        </>
      );
    }
  };

  //Limita os caracteres de exibição do nome do contato
  function truncateStringContactName(str) {
    if (str.length > 11) {
      return str.slice(0, 11) + '...';
    }
    return str;
  }

  // Renderiza o item da lista para o ticket, incluindo todas as informações e botões de ação
  return (
    <React.Fragment key={ticket.id}>
      <TicketMessagesDialog
        open={openTicketMessageDialog}
        handleClose={() => setOpenTicketMessageDialog(false)}
        ticketId={ticket.id}
      ></TicketMessagesDialog>

      <ListItem dense button
        onClick={() => {
          // if (ticket.status === "pending") return; // Impede a seleção de tickets pendentes
          handleSelectTicket(ticket); // Seleciona o ticket
        }}
        selected={ticketId && +ticketId === ticket.id} // Marca o ticket como selecionado
        className={clsx(classes.ticket, {
          [classes.pendingTicket]: ticket.status === "pending",
        })}
      >
        {/* Exibe uma cor associada à fila do ticket */}
        {/* <Tooltip arrow placement="right" title={ticket.queue?.name?.toUpperCase() || "SEM FILA"}>
          <span style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }} className={classes.ticketQueueColor}></span>
        </Tooltip> */}

        <div style={{ position: "relative", display: "flex", alignItems: "center", height: "100%" }}>
          {/* Avatar do contato do ticket */}
          <ListItemAvatar >

            <Avatar
              style={{
                // marginTop: "-20px",
                marginLeft: "-3px",
                width: "50px",
                height: "50px",
                borderRadius: "100%",
              }}
              src={ticket?.contact?.profilePicUrl}
            />


          </ListItemAvatar>
          {/* Exibe o ícone de "Espiar Conversa" se o usuário for administrador */}
          {profile === "admin" && (
            <Tooltip title="Espiar Conversa">
              <span className={classes.SpyOnConversation} onClick={(e) => {
                e.stopPropagation(); // Impede que o evento seja disparado
                setOpenTicketMessageDialog(true)
              }}>
                <VisibilityIcon
                  style={{
                    fontSize: "16px"
                  }}
                />
              </span>
            </Tooltip>

          )}
        </div>

        {/* Texto principal e secundário do ticket */}
        <ListItemText
          disableTypography
          primary={
            <div
              style={{
                display: "flex", // Nome e setor na mesma linha
                justifyContent: "space-between", // Nome/setores à esquerda, tags/data à direita
                alignItems: "center", // Alinha verticalmente os elementos
                flexWrap: "wrap", // Permite que o nome/setores quebrem a linha se necessário
              }}
            >
              {/* Contêiner para Nome e Setor */}
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap", // Permite quebra de linha para os setores
                  alignItems: "center",
                  marginBottom: "1px",
                }}
              >
                <Typography
                  noWrap
                  component="span"
                  variant="body2"
                  color="textPrimary"
                  style={{
                    marginRight: "6px", // Espaço entre o nome e o setor
                  }}
                >
                  {truncateStringContactName(ticket.contact.name)}
                </Typography>
              </div>

              {/* Tags na Extrema Direita */}
              <div
                style={{
                  display: "flex",
                  gap: "2px",
                  paddingLeft: "50px"
                }}
              >
                {/*Quebra linha das tags */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap", // Permite quebra de linha para as tags
                    marginBottom: "4px",
                  }}
                ></div>
                {tag?.map((tag) => (
                  <ContactTag
                    tag={tag}
                    key={`ticket-contact-tag-${ticket.id}-${tag.id}`}
                  />
                ))}
              </div>
            </div>
          }
          secondary={
            <div>
              {/* Última Mensagem */}
              <Typography
                noWrap
                component="span"
                variant="body2"
                color="textSecondary"
                className={classes.contactLastMessage}
                style={{ paddingRight: "40px", paddingTop: "20px" }}
              >
                {ticket.lastMessage && (
                  ticket.lastMessage.includes('data:image/png;base64') ? (
                    <MarkdownWrapper>Localização</MarkdownWrapper>
                  ) : (
                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                  )
                )}
              </Typography>

              <>
              {ticket?.user?.name && (
                <span
                      key={ticket.user.id}
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row-reverse",
                        padding: "1px 5px",
                        fontSize: "12px",
                        borderRadius: "9px",
                        backgroundColor: "#f8f8f8",
                        color: "black",
                        border: "1px solid #ccc",
                        whiteSpace: "nowrap",
                        marginLeft: "3px", // Espaço entre múltiplos setores
                        marginTop: "7px"
                      }}
                    >
                      {ticket.user.name}
                    </span>
                )}
                {ticket?.queue ? (
                  <span
                  key={ticket.queue.id}
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row-reverse",
                    padding: "1px 5px",
                    fontSize: "12px",
                    borderRadius: "9px",
                    backgroundColor: ticket.queue.color || "#f8f8f8",
                    color: "#fff",
                    border: `1px solid ${ticket.queue.color || "#ccc"}`,
                    whiteSpace: "nowrap",
                    marginLeft: "3px", // Espaço entre múltiplos setores
                    marginTop: "7px"
                  }}
                >
                  {ticket.queue.name}
                </span>
                )
                : (
                  <span
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "1px 5px",
                      fontSize: "12px",
                      borderRadius: "9px",
                      backgroundColor: "#f8f8f8",
                      color: "#333",
                      border: "1px solid #ccc",
                      marginLeft: "3px", // Espaço entre o nome e "Sem setor"
                    }}
                  >
                    Sem setor
                  </span>
                )}
              </>
            </div>
          }

        />

        {/* Botões de ação para aceitar, fechar e reabrir o ticket */}
        <ListItemSecondaryAction>
          {ticket.lastMessage && (
            <Typography className={classes.lastMessageTime} component="span" variant="body2" color="textSecondary">
              {isSameDay(parseISO(ticket.updatedAt), new Date()) ? format(parseISO(ticket.updatedAt), "HH:mm") : format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}
            </Typography>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      {/* Botões de ação para aceitar, fechar e reabrir o ticket */}
      {/* <span className={classes.secondaryContentSecond}>
        {ticket.status === "pending" && (
          <ButtonWithSpinner
            style={{ backgroundColor: 'green', color: 'white', padding: '0px', bottom: '17px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={e => handleAcceptTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.accept")}
          </ButtonWithSpinner>
        )}
        {(ticket.status !== "closed") && (
          <ButtonWithSpinner
            style={{ backgroundColor: 'red', color: 'white', padding: '0px', bottom: '0px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={e => handleCloseTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.closed")}
          </ButtonWithSpinner>
        )}
        {(ticket.status === "closed") && (
          <ButtonWithSpinner
            style={{ backgroundColor: 'red', color: 'white', padding: '0px', bottom: '0px', borderRadius: '0px', left: '8px', fontSize: '0.6rem' }}
            variant="contained"
            className={classes.acceptButton}
            size="small"
            loading={loading}
            onClick={e => handleReopenTicket(ticket.id)}
          >
            {i18n.t("ticketsList.buttons.reopen")}
          </ButtonWithSpinner>
        )}
      </span> */}

      {/* Divisor visual entre os itens da lista */}
      <Divider variant="inset" component="li" />

    </React.Fragment>
  );
};

export default TicketListItemCustom;
