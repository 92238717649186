import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@mui/icons-material/Add';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastErrorFuntional";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	titleContainer: {
		display: "flex",
		justifyContent: "space-between",
	},

	titleText: {
		fontWeight: 500,
		fontSize: "18px",
	},

	dialogContainer: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(2),
	},

	textFieldContainer: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		gap: "16px",
		// marginTop: "28px",
	},

	textField: {
		width: "100%",
		borderRadius: '16px',
		// marginRight: theme.spacing(1),
		// flex: 1,
	},

	extraInfoTitleContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "baseline",
		gap: "8px",
	},

	addButton: {
		backgroundColor: "#6EB131 !important",
		color: "#fff",
		borderRadius: "16px",
		padding: 10,
		"&:hover": {
			backgroundColor: theme.palette.primary.dark, // Cor mais escura no hover
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ContactSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email"),
});

const ContactModal = ({ open, onClose, contactId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);

	const initialState = {
		name: "",
		number: "",
		email: "",
	};

	const [contact, setContact] = useState(initialState);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchContact = async () => {
			if (initialValues) {
				setContact({ ...initialState, ...initialValues }); // Garante que todos os campos tenham valores padrão
				return;
			}

			if (!contactId) return;

			try {
				const { data } = await api.get(`/contacts/${contactId}`);
				if (isMounted.current) {
					setContact({ ...initialState, ...data }); // Mescla com o estado inicial
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchContact();
	}, [contactId, open, initialValues]);

	const initialFormikValues = {
		name: contact.name || "",
		number: contact.number || "",
		email: contact.email || "",
		extraInfo: contact.extraInfo || [], // Garante que o campo exista
	};


	const handleClose = () => {
		onClose();
		setContact(initialState);
	};

	const handleSaveContact = async (values) => {
		const normalizedValues = {
			...values,
			number: values.number.replace(/\D/g, ""),
		};

		console.log("Valores normalizados para envio:", normalizedValues);  // Adicione esse log

		try {
			if (contactId) {
				console.log("Editando contato existente");
				await api.put(`/contacts/${contactId}`, normalizedValues);
				handleClose();
			} else {
				console.log("Adicionando um novo contato");
				const { data } = await api.post("/contacts", normalizedValues);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("contactModal.success"));
		} catch (err) {
			console.error("Erro ao salvar contato:", err); // Log adicional para o erro
			if (err.response && err.response.data) {
				const { message } = err.response.data;
				if (message === "ERR_DUPLICATED_CONTACT") {
					toast.error(i18n.t("contactModal.errors.duplicatedNumber"));
				} else if (message === "ERR_CHECK_NUMBER") {
					toast.error(i18n.t("contactModal.errors.invalidNumber"));
				} else {
					toastError(err);  // Use o método de toastError para exibir o erro
				}
			} else {
				toastError(err);
			}
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle disableTypography id="form-dialog-title" className={classes.titleContainer}>
					<div>
						<Typography className={classes.titleText}>
							{contactId
								? `${i18n.t("contactModal.title.edit")}`
								: `${i18n.t("contactModal.title.add")}`}</Typography>
						<Typography style={{ fontSize: 14, color: "grey" }}>
							Insira as informações de contato do seu cliente.
						</Typography>
					</div>
					<IconButton onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<Formik
					initialValues={initialFormikValues}
					enableReinitialize={true}
					validationSchema={ContactSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveContact(values);
							actions.setSubmitting(false);
						}, 50);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers className={classes.dialogContainer}>
								{<Typography variant="subtitle1" gutterBottom>
									{i18n.t("contactModal.form.mainInfo")}
								</Typography>}
								<div style={{ marginTop: "32px" }} className={classes.textFieldContainer}>

									<Field
										as={TextField}
										label={i18n.t("contactModal.form.name")}
										name="name"
										autoFocus
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<Field
										as={TextField}
										label={i18n.t("contactModal.form.number")}
										name="number"
										error={touched.number && Boolean(errors.number)}
										helperText={touched.number && errors.number}
										placeholder="5541998608485"
										variant="outlined"
										margin="dense"
										className={classes.textField}

									/>
								</div>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.email")}
									name="email"
									style={{ marginTop: '16px' }}
									error={touched.email && Boolean(errors.email)}
									helperText={touched.email && errors.email}
									placeholder="Email address"
									fullWidth
									margin="dense"
									variant="outlined"
									className={classes.textField}
								/>
								{/*<Typography
									// style={{ marginBottom: 8, marginTop: 12 }}
									variant="subtitle1"
								>
									{i18n.t("contactModal.form.whatsapp")} {contact?.whatsapp ? contact?.whatsapp.name : ""}
								</Typography>
								*/}

								<FieldArray name="extraInfo">
									{({ push, remove }) => (
										<>
											<div className={classes.extraInfoTitleContainer}>

												<Typography
													style={{ fontWeight: 700, }}
													variant="subtitle1"
												>
													{i18n.t("contactModal.form.extraInfo")}
												</Typography>
												<IconButton className={classes.addButton} onClick={() => push({ name: "", value: "" })}>
													<AddIcon />
												</IconButton>
											</div>
											{values.extraInfo &&
												values.extraInfo.length > 0 &&
												values.extraInfo.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<div style={{ marginTop: "12px" }} className={classes.textFieldContainer}>
															<Field
																as={TextField}
																label={i18n.t("contactModal.form.extraName")}
																name={`extraInfo[${index}].name`}
																variant="outlined"
																margin="dense"
																className={classes.textField}
															/>
															<Field
																as={TextField}
																label={i18n.t("contactModal.form.extraValue")}
																name={`extraInfo[${index}].value`}
																variant="outlined"
																margin="dense"
																className={classes.textField}
															/>
														</div>
														<IconButton
															size="medium"
															onClick={() => remove(index)}
															style={{ padding: 4 }}
														>
															<DeleteOutlineIcon />
														</IconButton>
													</div>
												))}
											{values.extraInfo && values.extraInfo.length > 0 && (
												<div className={classes.extraAttr}>
													<Button
														style={{ flex: 1, marginTop: 8, border: "none" }}
														variant="outlined"
														color="primary"
														onClick={() => push({ name: "", value: "" })}
													>
														{`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
													</Button>
												</div>)}
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									// color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{contactId
										? `${i18n.t("contactModal.buttons.okEdit")}`
										: `${i18n.t("contactModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ContactModal;
