import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import Board from 'react-trello';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import Button from '@material-ui/core/Button'; // Importe o botão
import TagModal from "../../components/TagModal"; // Importe o TagModal


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  button: {
    background: "#6EB131",
    border: "none",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    borderRadius: "16px",
    "&:hover": {
      cursor: "pointer"
    }
  },
  laneHeader: {
    maxHeight: "40px",
    paddingLeft: "10px",
    paddingRight: "10px",
    backgroundColor: theme.palette.cardnormal,
    color: theme.palette.textosKanbanHeader,
    display: "flex",
    borderRadius: "16px",
    fontSize: "12px",
    border: "1px #DBDBDB solid",
    justifyContent: "space-between",
    alignItems: "center",

  },
  LaneStylex: {
    backgroundColor: theme.palette.cardnormal,
    color: theme.palette.textosKanbanHeader,
    borderRadius: '16px'
  },
  laneContainer: { // Corrigido: agora é um objeto
    '& .sc-cwHptR': {
      maxHeight: '750px', // Define a altura máxima da "lane"
      overflowY: 'auto', // Habilita o scroll vertical
      '&::-webkit-scrollbar': {
        width: '8px', // Largura do scrollbar
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: "#6EB131", // Cor do thumb do scrollbar
        borderRadius: '4px', // Borda arredondada do thumb
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.inputBackgroundColor, // Cor do track do scrollbar
      },
    },
    "& .dOlrNy": {
      height: "auto !important",
    }
  },
}));


const CustomLaneHeader = ({ title, label, color }) => {
  const classes = useStyles();
  console.log(color)
  return (
    <div className={classes.laneHeader}>
      <h3>{title}</h3>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {color &&
          <span style={{ width: '14px', height: '14px', backgroundColor: color, borderRadius: '100%', display: 'inline-block', marginRight: "5px" }}></span>
        }
        <span>{label}</span>
      </div>
    </div>
  );
};

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();

  const [tags, setTags] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [file, setFile] = useState({ lanes: [] });
  const [tickets, setTickets] = useState([]);
  const { user } = useContext(AuthContext);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);

  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      setTags(fetchedTags);
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleCloseTagModal = () => {
    setTagModalOpen(false);
  };

  console.log(tags);

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: {
          queueIds: JSON.stringify(jsonString),
          teste: true
        }
      });
      setTickets(data.tickets);
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  const popularCards = () => {
    const filteredTickets = tickets.filter(ticket => ticket.tags.length === 0);

    const lanes = [
      {
        id: "lane0",
        title: i18n.t("kanban.step_open"),
        label: "0",
        cards: filteredTickets.map(ticket => ({
          id: ticket.id.toString(),
          label: (
            <span style={{ color: theme.palette.textosKanbanHeader, }}>

              Ticket nº {ticket.id.toString()}
            </span>
          ),
          description: (
            <div style={{ color: theme.palette.textosKanbanHeader, }}>
              <p>
                {ticket.contact.number}
                <br />
                {ticket.lastMessage}
              </p>
              <button
                className={classes.button}
                onClick={() => {
                  handleCardClick(ticket.uuid);
                }}
              >
                {i18n.t("kanban.buttons.open_ticket")}
              </button>
            </div>
          ),

          title: (
            <span style={{ color: theme.palette.textosKanbanHeader, overflow: "hidden", width: "100%", display: "flex" }}>

              {ticket.contact.name}
            </span>
          ),
          draggable: true,
          href: "/tickets/" + ticket.uuid,
        })),
        style: {
          backgroundColor: theme.palette.type === "dark"
            ? theme.palette.cardnormal
            : theme.palette.tabHeaderBackground,
          color: theme.palette.textosKanbanHeader,
          borderRadius: "16px",
        },
        cardStyle: {
          borderRadius: "16px",
          backgroundColor: theme.palette.type === "dark" ? theme.palette.inputBackgroundColor : "#fff",
          borderColor: theme.palette.bordabox,
        },


      },
      ...tags.map(tag => {
        const filteredTickets = tickets.filter(ticket => {
          const tagIds = ticket.tags.map(tag => tag.id);
          return tagIds.includes(tag.id);
        });

        return {
          id: tag.id.toString(),
          title: tag.name,
          label: tag.id.toString(),
          color: tag.color, // Usar a cor da tag para o cabeçalho
          cards: filteredTickets.map(ticket => ({
            id: ticket.id.toString(),
            label: "Ticket nº " + ticket.id.toString(),
            label: (
              <span style={{ color: theme.palette.textosKanbanHeader, }}>
  
                Ticket nº {ticket.id.toString()}
              </span>
            ),
            description: (
              <div style={{ color: theme.palette.textosKanbanHeader, }}>
                <p>
                  {ticket.contact.number}
                  <br />
                  {ticket.lastMessage}
                </p>
                <button
                  className={classes.button}
                  onClick={() => {
                    handleCardClick(ticket.uuid);
                  }}
                >
                  {i18n.t("kanban.buttons.open_ticket")}
                </button>
              </div>
            ),
            title: (
              <span style={{ color: theme.palette.textosKanbanHeader, overflow: "hidden", width: "100%", display: "flex" }}>
  
                {ticket.contact.name}
              </span>
            ),
            draggable: true,
            href: "/tickets/" + ticket.uuid,
          })),
          style: {
            backgroundColor: theme.palette.type === "dark"
              ? theme.palette.inputBackgroundColor
              : theme.palette.tabHeaderBackground,
            color: theme.palette.textosKanbanHeader,
            borderRadius: "16px",
          },
          cardStyle: {
            borderRadius: "16px",
            backgroundColor: theme.palette.type === "dark" ? theme.palette.inputBackgroundColor : "#fff",

            borderColor: theme.palette.bordabox,
          },
        };
      }),
    ];

    setFile({ lanes });
  };

  const handleCardClick = (uuid) => {
    history.push('/tickets/' + uuid);
  };

  useEffect(() => {
    popularCards();
  }, [tags, tickets, reloadData, theme]);

  const handleCardMove = async (cardId, sourceLaneId, targetLaneId) => {
    try {
      await api.delete(`/ticket-tags/${targetLaneId}`);
      // toast.success('Ticket Tag Removido!');
      console.log('Ticket Tag Removido!');
      await api.put(`/ticket-tags/${targetLaneId}/${sourceLaneId}`);
      // toast.success('Ticket Tag Adicionado com Sucesso!');
      console.log('Ticket Tag Adicionado com Sucesso!');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px', marginRight: '5px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenTagModal}
        >
          {i18n.t("kanban.buttons.create_step")}
        </Button>

      </div>
      <div className={`${classes.root} ${classes.laneContainer}`} style={{ marginTop: '5px' }}>
        <Board
          data={file}
          onCardMoveAcrossLanes={handleCardMove}
          style={{ backgroundColor: 'rgba(252, 252, 252, 0)' }}
          components={{ LaneHeader: CustomLaneHeader }} // Custom Header
        />
        {/* Modal para criar uma nova etapa */}
        <TagModal
          open={tagModalOpen}
          onClose={handleCloseTagModal}
          reload={fetchTags}
          tagId={selectedTag && selectedTag.id}
          isKanban={true}
        />
      </div>
    </>
  );
};

export default Kanban;
