import { useState, useEffect, useCallback } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

const useMessages = ({ fromMe, dateStart, dateEnd }) => {
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);

    // Função para buscar mensagens
    const fetchMessages = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await api.get("/messages-allMe", {
                params: {
                    fromMe,
                    dateStart,
                    dateEnd,
                },
            });
            setCount(data.count[0].count);
        } catch (err) {
            toastError(err);
        } finally {
            setLoading(false);
        }
    }, [fromMe, dateStart, dateEnd]);

    // Debounce para controlar as requisições
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchMessages();
        }, 50);

        return () => clearTimeout(delayDebounceFn); // Limpa o debounce anterior
    }, [fetchMessages]); // fetchMessages já é memorizado com dependências

    return { count, loading };
};

export default useMessages;
