import React, { useContext, useEffect, useState, useMemo, useRef } from "react";

import { toast } from "react-toastify";
import api from "../../services/api";
import toastError from "../../errors/toastError";

import {
  Box,
  Button,
  Dialog,
  makeStyles,
  DialogTitle,
  DialogActions,
  IconButton,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import MessagesList from "../MessagesList";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import { SocketContext } from "../../context/Socket/SocketContext";
import CloseIcon from "@material-ui/icons/Close";
import TransferTicketModalCustom from "../TransferTicketModalCustom";
import ConfirmationModal from "../ConfirmationModal";

import { Can } from "../Can";
import ButtonWithText from "../ButtonWithText";

import { i18n } from "../../translate/i18n";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },

  titleContainer: {
    position: "relative",
    padding: 0,
  },
  closeIcon: {
    position: "absolute",
    right: 4,
    top: "20%",
  },

  mainWrapper: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    marginRight: -drawerWidth,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  mainWrapperShift: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },

  mainPendingWrapper: {
    backgroundColor: theme.palette.type === 'dark' ? theme.palette.cardnormal : theme.palette.tabHeaderBackground,
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: "8px 16px",
    gap: "8px",
  },

  DeleteButton: {
    backgroundColor: "#E25451 !important",
    color: theme.palette.contrastText,
    border: `1px solid ${theme.palette.divider}`,
    padding: "8px 18px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },

  TransferButton: {
    backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.cardnormal : "grey"} !important`,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.divider}`,
    padding: "8px 18px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },

  AttendButton: {
    backgroundColor: `#6EB131 !important`,
    color: theme.palette.contrastText,
    padding: "8px 18px",
    borderRadius: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    fontSize: "14px",
    fontWeight: 600,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:disabled": {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  },
}));

export default function TicketMessagesDialog({ open, handleClose, ticketId }) {
  const history = useHistory();
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const socketManager = useContext(SocketContext);

  const [loading, setLoading] = useState(true);
  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});
  const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const socketRef = useRef(null);

  const fetchTicket = useRef(async () => {
    if (!ticketId) return;
    setLoading(true);
    try {
      const { data } = await api.get(`/tickets/${ticketId}`);
      const { queueId } = data;
      const { queues, profile } = user;
      const queueAllowed = queues.some((q) => q.id === queueId);
      if (!queueAllowed && profile !== "admin") {
        toast.error("Acesso não permitido");
        history.push("/tickets");
        return;
      }
      setContact(data.contact);
      setTicket(data);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!open || !ticketId) return;

    fetchTicket.current();

    const companyId = localStorage.getItem("companyId");
    const socket = socketManager.getSocket(companyId);
    socketRef.current = socket;

    socket.emit("joinChatBox", `${ticketId}`);

    const handleTicketUpdate = (data) => {
      if (data.action === "update" && data.ticket.id === ticketId) {
        setTicket(data.ticket);
      }
      if (data.action === "delete" && data.ticketId === ticketId) {
        history.push("/tickets");
      }
    };

    const handleContactUpdate = (data) => {
      if (data.action === "update") {
        setContact((prevState) =>
          prevState.id === data.contact?.id ? { ...prevState, ...data.contact } : prevState
        );
      }
    };

    socket.on(`company-${companyId}-ticket`, handleTicketUpdate);
    socket.on(`company-${companyId}-contact`, handleContactUpdate);

    return () => {
      socket.off(`company-${companyId}-ticket`, handleTicketUpdate);
      socket.off(`company-${companyId}-contact`, handleContactUpdate);
      socket.disconnect();
      socketRef.current = null;
    };
  }, [open, ticketId, socketManager, history]);

  const renderTicketInfo = useMemo(() => {
    if (ticket.user !== undefined) {
      return (
        <TicketHeader loading={loading}>
          <TicketInfo
            contact={contact}
            ticket={ticket}
            onClick={() => setTransferTicketModalOpen(true)}
          />
        </TicketHeader>
      );
    }
  }, [ticket, contact, loading]);

  return (
    <>
      <Dialog maxWidth="md" onClose={handleClose} open={open}>
        <DialogTitle disableTypography className={classes.titleContainer}>
          {renderTicketInfo}
          <IconButton onClick={handleClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ReplyMessageProvider>
          <Box className={classes.root}>
            <MessagesList
              ticket={ticket}
              ticketId={ticket.id}
              isGroup={ticket.isGroup}
            />
          </Box>
        </ReplyMessageProvider>
      </Dialog>

      <TransferTicketModalCustom
        modalOpen={transferTicketModalOpen}
        onClose={() => setTransferTicketModalOpen(false)}
        ticketid={ticketId}
      />

      <ConfirmationModal
        title={`Deseja excluir o ticket #${ticketId}?`}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={async () => {
          try {
            await api.delete(`/tickets/${ticketId}`);
            setConfirmationOpen(false);
            history.push("/tickets");
          } catch (err) {
            toastError(err);
          }
        }}
      >
        Deseja realmente excluir este ticket?
      </ConfirmationModal>
    </>
  );
}