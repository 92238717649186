import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, ListItemText, Typography, makeStyles, IconButton, InputAdornment } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import { AuthContext } from "../../context/Auth/AuthContext";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  maxWidth: {
    width: "100%",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start"
  },

  titleText: {
    fontWeight: 600,
    fontSize: "20px",
  },

  textField: {
    width: "100%",
    backgroundColor: theme.palette.inputBackgroundColor,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "16px",
    '& .MuiOutlinedInput-root': {
      width: "100%",
      padding: "12px",
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input': {
        backgroundColor: theme.palette.inputBackgroundColor,
        border: 'none !important',
      },
      "& .MuiAutocomplete-clearIndicator": {
        display: "none",
      },
      "& .MuiAutocomplete-input": {
        padding: 4,
      }
    },
    '& .MuiInputBase-input': {
      color: "#7d7d7d",
      fontSize: "16px",
      padding: 0,
    },
  },

  customLabel: {
    fontWeight: 600,
    fontSize: "14px",
    padding: 0,
    marginBottom: "-2px",
  },

  select: {
    "& .MuiSelect-root": {
      borderRadius: "16px",
    }
  },
}));

const filterOptions = createFilterOptions({
  trim: true,
});

const TransferTicketModalCustom = ({ modalOpen, onClose, ticketid }) => {
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [allQueues, setAllQueues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState("");
  const classes = useStyles();
  const { findAll: findAllQueues } = useQueues();
  const isMounted = useRef(true);
  const [whatsapps, setWhatsapps] = useState([]);
  const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
  const { user } = useContext(AuthContext);
  const { companyId, whatsappId } = user;

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!modalOpen) return; // Execute only if modal is open

    setLoading(true);
    const fetchInitialData = async () => {
      try {
        const [whatsappsData, queuesData] = await Promise.all([
          api.get(`/whatsapp`, { params: { companyId, session: 0 } }),
          findAllQueues(),
        ]);

        setWhatsapps(whatsappsData.data);
        setAllQueues(queuesData);
        setQueues(user.queues.length === 1 ? [user.queues[0]] : queuesData);

        if (whatsappId) setSelectedWhatsapp(whatsappId);
        if (user.queues.length === 1) setSelectedQueue(user.queues[0].id);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialData();
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen || searchParam.length < 3) return; // Prevent unnecessary requests

    const delayDebounceFn = setTimeout(async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/users/", { params: { searchParam } });
        setOptions(data.users);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    }, 50); // Debounce

    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, modalOpen]);

  const handleClose = () => {
    onClose();
    setSearchParam("");
    setSelectedUser(null);
  };

  const handleSaveTicket = async (e) => {
    e.preventDefault();
    if (!ticketid || !selectedQueue) return;
    setLoading(true);
    try {
      let data = {
        queueId: selectedQueue,
        userId: selectedUser?.id || null,
        status: selectedUser ? undefined : "pending",
        whatsappId: selectedWhatsapp,
      };

      await api.put(`/tickets/${ticketid}`, data);
      history.push(`/tickets`);
    } catch (err) {
      toastError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose} maxWidth="sm" scroll="paper">
      <form onSubmit={handleSaveTicket}>
        <DialogTitle disableTypography className={classes.titleContainer}>
          <div>
            <Typography className={classes.titleText}>
              {i18n.t("transferTicketModal.title")}
            </Typography>
            <Typography style={{ fontSize: 14, color: "grey" }}>
              Gerencie o fluxo de atendimento do seu cliente, seja para o mesmo setor ou setores diferentes.
            </Typography>
          </div>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            style={{ marginBottom: 5, marginTop: "36px" }}
            getOptionLabel={(option) => `${option.name}`}
            onChange={(e, newValue) => {
              setSelectedUser(newValue);
              setQueues(newValue?.queues || allQueues);
              if (!newValue) setSelectedQueue("");
            }}
            options={options}
            filterOptions={filterOptions}
            freeSolo
            autoHighlight
            noOptionsText={i18n.t("transferTicketModal.noOptions")}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={i18n.t("transferTicketModal.fieldLabel")}
                variant="outlined"
                autoFocus
                onChange={(e) => setSearchParam(e.target.value)}
                className={classes.textField}
                placeholder='Pesquise por nome, tag, data, protocolo'
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {loading && <CircularProgress color="inherit" size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          <FormControl variant="outlined" className={classes.maxWidth}>
            <label className={classes.customLabel}>
              {i18n.t("transferTicketModal.fieldQueueLabel")}
            </label>
            <Select
              value={selectedQueue}
              onChange={(e) => setSelectedQueue(e.target.value)}
              className={classes.select}
            >
              {queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <label className={classes.customLabel}>
                Selecione uma conexão
              </label>
              <Select
                required
                fullWidth
                displayEmpty
                variant="outlined"
                value={selectedWhatsapp}
                className={classes.select}
                onChange={(e) => setSelectedWhatsapp(e.target.value)}
                renderValue={() => {
                  if (!selectedWhatsapp) return "Chip";
                  const whatsapp = whatsapps.find(w => w.id === selectedWhatsapp);
                  return whatsapp?.name || "Chip";
                }}
              >
                {whatsapps.map((whatsapp, key) => (
                  <MenuItem key={key} value={whatsapp.id}>
                    <ListItemText
                      primary={
                        <>
                          <Typography style={{ fontSize: 14, marginLeft: "10px", display: "inline-flex", alignItems: "center", lineHeight: "2" }}>
                            {whatsapp.name} ({whatsapp.status})
                          </Typography>
                        </>
                      }
                    />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {i18n.t("transferTicketModal.buttons.cancel")}
          </Button>
          <ButtonWithSpinner
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
          >
            {i18n.t("transferTicketModal.buttons.ok")}
          </ButtonWithSpinner>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TransferTicketModalCustom;
